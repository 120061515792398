import styled from "@emotion/styled";
import { MouseEvent, useEffect, useRef, useState } from "react";
import { Alert, MenuItem, menuItemClasses, Typography } from "@kaltura/ds-react-components";
import { Check16Icon, Globe24Icon, Link24Icon, Lock24Icon } from "@kaltura/ds-react-icons";
import { translate } from "@kaltura/mediaspace-shared-utils";
import { HtmlReactParser } from "@kaltura/mediaspace-shared-utils";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";
import {
    ButtonClickAnalyticsType,
    PublishStatus,
    SharedMenuItemProps,
    PublishSaveResponseData,
    KmsTypeCategoryMembership,
} from "@kaltura/mediaspace-shared-types";
import { ModalManager } from "./modals/ModalManager";

export interface PublishMenuItemsProps extends SharedMenuItemProps {
    entryIds: string[];
    /**
     * current publishStatus is undefined in bulk publish
     */
    publishStatus?: PublishStatus;
    enableUnlisted?: boolean;
    crossApplicationUnpublishing?: boolean;
    allowUnpublishPublished?: boolean;
    allowPublish?: boolean;
    warningMessages?: string[];
    onCloseMenu?: () => void;
    onItemStateChange?: (key: string, value: any) => void;
    reloadMedia?: () => void;
    /**
     * boolean flag to indicate bulk publish
     * using entryIds->length is not enough
     * because a single entry might be selected in bulk
     */
    isBulk?: boolean;
    disabledCategoryMembershipIds?: KmsTypeCategoryMembership[];
}

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    height: "auto",
    maxWidth: 300,
    "& > div": {
        height: "auto",
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    [`& .${menuItemClasses.title}`]: {
        display: "flex",
    },
}));

const StyledAlertMenuItem = styled(MenuItem)(({ theme }) => ({
    height: "auto",
    maxWidth: 300,
    marginTop: theme.spacing(1),
    "&:focus, &:focus > div, &:hover, &:hover > div": {
        backgroundColor: "transparent",
    },
    "& > div": {
        height: "auto",
    },
}));

const StyledIconWrapper = styled("span")(({ theme }) => ({
    marginRight: theme.spacing(1),
}));

const StyledLabelWrapper = styled("span")({
    display: "flex",
    flexDirection: "column",
    maxWidth: 236,
    textWrap: "wrap",
});

const StyledTypography = styled(Typography)(({ theme }) => ({
    color: theme.kaltura.palette.tone2,
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
    whiteSpace: "break-spaces",
    cursor: "default",
    "& a": {
        fontWeight: theme.typography.fontWeightBold,
        color: theme.kaltura.palette.tone1,
        textDecoration: "underline",
        cursor: "pointer",
        "&:hover": {
            color: theme.kaltura.palette.tone2,
            textDecoration: "none",
        },
    },
}));

/**
 * Publish indication menu items
 *
 * meant to be used in menu: <Menu><PublishMenuItems /></Menu> (my media, upload)
 * or in nested menu items:  <Menu><NestedMenuItem><PublishMenuItems /></NestedMenuItem></Menu> (media page)
 * and so - the top level component MUST be a fragment <></>
 */
export function PublishMenuItems(props: PublishMenuItemsProps) {
    const {
        entryIds,
        publishStatus,
        enableUnlisted = true,
        crossApplicationUnpublishing = false,
        allowUnpublishPublished = true,
        allowPublish = true,
        warningMessages,
        onCloseMenu,
        onItemStateChange,
        reloadMedia,
        isBulk = false,
        disabledCategoryMembershipIds = [],
    } = props;

    const [open, setOpen] = useState(false);
    const [targetStatus, setTargetStatus] = useState<PublishStatus>();
    const wasSubmitted = useRef(false);

    /**
     * reload my media list after publish request was submitted
     * done here instead of in below handleSubmit to allow media actions menu
     * and publish modal to close and only then reload the media list
     */
    useEffect(() => {
        return () => {
            if (wasSubmitted.current) {
                reloadMedia?.();
            }
        };
    }, []);

    const sendButtonAnalytics = useButtonAnalytics();

    // current publishStatus is not relevant for bulk publish
    const isPrivate = publishStatus === PublishStatus.PRIVATE;
    const isUnlisted = publishStatus === PublishStatus.UNLISTED;
    const isPublished = publishStatus === PublishStatus.PUBLISHED;

    const entryId = entryIds.length === 1 ? entryIds[0] : undefined;

    const handleSubmit = (result: PublishSaveResponseData) => {
        // reload media list after publish submit from my-media
        // see above useEffect for more details
        wasSubmitted.current = true;
        // update media page actions publish menu state
        // not relevant for bulk publish
        if (!isBulk) {
            onItemStateChange?.("publishStatus", result.publishStatus);
            onItemStateChange?.("warningMessages", result.warningMessages);
        }
        // close modal
        setOpen(false);
        // close menu
        onCloseMenu?.();
    };

    const handleClose = () => {
        // close modal
        setOpen(false);
    };

    const handlePrivateClick = () => {
        isPrivate && onCloseMenu?.();
        setOpen(!isPrivate);
        setTargetStatus(PublishStatus.PRIVATE);
        !isPrivate
            && sendButtonAnalytics(
                `Publishing indicator menu - Private ${isBulk && "(bulk)"}`,
                ButtonClickAnalyticsType.CHOOSE,
                entryId
            );
    };

    const handleUnlistedClick = () => {
        isUnlisted && onCloseMenu?.();
        setOpen(!isUnlisted);
        setTargetStatus(PublishStatus.UNLISTED);
        !isUnlisted
            && sendButtonAnalytics(
                `Publishing indicator menu - Unlisted ${isBulk && "(bulk)"}`,
                ButtonClickAnalyticsType.CHOOSE,
                entryId
            );
    };

    const handlePublishClick = () => {
        setOpen(true);
        setTargetStatus(PublishStatus.PUBLISHED);
        isPublished
            ? sendButtonAnalytics(
                  "Publishing indicator menu - Update publish locations",
                  ButtonClickAnalyticsType.CHOOSE,
                  entryId
              )
            : sendButtonAnalytics(
                  `Publishing indicator menu - Publish ${isBulk && "(bulk)"}`,
                  ButtonClickAnalyticsType.CHOOSE,
                  entryId
              );
    };

    const handleWarningClick = (event: MouseEvent<HTMLElement>) => {
        // beacon text supplied by legacy kms publish code
        // warnings are not shown in bulk publish
        const beacon = (event.target as HTMLElement).dataset?.beacon;
        if (beacon) {
            sendButtonAnalytics(beacon, ButtonClickAnalyticsType.EDIT, entryId);
        }
    };

    return (
        <>
            <StyledMenuItem
                key="private"
                className="kms-ds-publish-menu-item-private"
                onClick={handlePrivateClick}
                autoFocus={isPrivate}
                icon={isPrivate ? <Check16Icon /> : undefined}
                aria-current={isPrivate}
                disabled={!allowUnpublishPublished && isPublished}
            >
                <StyledIconWrapper>
                    <Lock24Icon />
                </StyledIconWrapper>
                <StyledLabelWrapper>
                    {translate("Private")}
                    <StyledTypography variant="body2">{translate("Only I can view")}</StyledTypography>
                </StyledLabelWrapper>
            </StyledMenuItem>
            {enableUnlisted && (
                <StyledMenuItem
                    key="unlisted"
                    className="kms-ds-publish-menu-item-unlisted"
                    onClick={handleUnlistedClick}
                    autoFocus={isUnlisted}
                    icon={isUnlisted ? <Check16Icon /> : undefined}
                    aria-current={isUnlisted}
                    disabled={!allowUnpublishPublished && isPublished}
                >
                    <StyledIconWrapper>
                        <Link24Icon />
                    </StyledIconWrapper>
                    <StyledLabelWrapper>
                        {translate("Unlisted")}
                        <StyledTypography variant="body2">
                            {translate("Anyone with the link can view")}
                        </StyledTypography>
                    </StyledLabelWrapper>
                </StyledMenuItem>
            )}
            <StyledMenuItem
                key="publish"
                className="kms-ds-publish-menu-item-publish"
                onClick={handlePublishClick}
                autoFocus={isPublished}
                icon={isPublished ? <Check16Icon /> : undefined}
                aria-current={isPublished}
                disabled={!allowPublish}
            >
                <StyledIconWrapper>
                    <Globe24Icon />
                </StyledIconWrapper>
                <StyledLabelWrapper>
                    {isPublished ? translate("Change publish locations") : translate("Publish")}
                    <StyledTypography variant="body2">
                        {translate("Anyone with entitlements to the gallery/channel can view")}
                    </StyledTypography>
                </StyledLabelWrapper>
            </StyledMenuItem>
            {warningMessages
                && warningMessages.map((warningMessage, index) => (
                    <StyledAlertMenuItem key={`warn_${index}`} onClick={handleWarningClick}>
                        <StyledAlert severity="warning" icon={false}>
                            {HtmlReactParser(warningMessage)}
                        </StyledAlert>
                    </StyledAlertMenuItem>
                ))}

            {/* mount modals to force data fetch */}
            {open && !!targetStatus && (
                <ModalManager
                    entryIds={entryIds}
                    crossApplicationUnpublishing={crossApplicationUnpublishing}
                    publishStatus={publishStatus}
                    targetStatus={targetStatus}
                    onSubmit={handleSubmit}
                    onClose={handleClose}
                    isBulk={isBulk}
                    disabledCategoryMembershipIds={disabledCategoryMembershipIds}
                />
            )}
        </>
    );
}

export default PublishMenuItems;
